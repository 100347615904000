/*eslint-disable */
var clearTimeoutState;

import { bus } from '@/main';
import toast from '@/services/toast';

export default {
  name: 'login',
  components: {},
  props: [],
  data() {
    return {
      email: '',
      password: '',
      submitted: false,
      isRememberMe: false,
      mblLogined: false,
      userCheckNotValidLogin: true,
      userCheckValidLogin: false,
      identity: '',
      otp: '',
      countDown: 30,
      resentOtpFlag:false
    }
  },
  computed: {

  },
  mounted() {
    bus.$emit('headerDisabledBus', false);
    if (localStorage.getItem('token') != null && localStorage.getItem('LoginedUserID') != null) {
      this.$router.push('/dashboard/');
    }
  },
  methods: {
    countDownTimer() {
      if(this.countDown > 0) {
        clearTimeoutState = setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resendOtpMethod() {
      if (this.validateEmail(this.identity)) {
        this.mblLogined = false
      } else {
        this.mblLogined = true
      }
      if (this.mblLogined == true) {
          this.$store.dispatch('resentOtpForLoginAction', {
              mobile: this.identity
            })
            .then((res) => {
              this.verfiyIdentity = this.identity
              this.userCheckNotValidLogin = false
              this.userCheckValidLogin = true
              toast.success(res.data.user_msg);
              this.countDown = 30
              this.countDownTimer();
              this.resentOtpFlag = true
            }).catch((err) => {
              toast.error(err.msg || err.data.msg);
            })
          return;
      } else {
        this.$store.dispatch('resentOtpForLoginAction', {
            email: this.identity
          })
          .then((res) => {
            this.verfiyIdentity = this.identity
            this.userCheckNotValidLogin = false
            this.userCheckValidLogin = true
            toast.success(res.data.user_msg);
            this.countDown = 30
            this.countDownTimer();
            this.resentOtpFlag = true
          }).catch((err) => {
            toast.error(err.msg || err.data.msg);
          })
        return;
      }
    },
    showNextScreenForLogin() {
      if (this.validateEmail(this.identity)) {
        this.mblLogined = false
      } else {
        this.mblLogined = true
      }
      if (this.mblLogined == true) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submitted = true
            this.$store.dispatch('requestOtpForLoginAction', {
                mobile: this.identity
              })
              .then((res) => {
                this.verfiyIdentity = this.identity
                this.userCheckNotValidLogin = false
                this.userCheckValidLogin = true
                this.submitted = false
                toast.success(res.data.user_msg);
                this.countDownTimer();
              }).catch((err) => {
                this.submitted = false
                toast.error(err.msg || err.data.msg);
              })
            return;
          }
        });
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submitted = true
            this.$store.dispatch('requestOtpForLoginAction', {
                email: this.identity
              })
              .then((res) => {
                this.verfiyIdentity = this.identity
                this.userCheckNotValidLogin = false
                this.userCheckValidLogin = true
                this.submitted = false
                toast.success(res.data.user_msg);
                this.countDownTimer();
              }).catch((err) => {
                this.submitted = false
                toast.error(err.msg || err.data.msg);
              })
            return;
          }
        });
      }
    },
    validateLoginBeforeSubmit() {
      if (this.userCheckNotValidLogin) {
        this.showNextScreenForLogin();
      }
    },
    validateBeforeSubmit(e) {
      if (this.mblLogined == true) {
        this.$validator.validateAll().then((result) => {
          this.submitted = true;
          if (result) {
            this.loginBtnDisabled = true
            this.$store.dispatch('loginOtpVerifyAction', {
                mobile: this.verfiyIdentity,
                otp: this.otp
              })
              .then((res) => {
                document.getElementById('preloader').style.display = "flex";
                toast.success(res.data.msg);
                this.submitted = false;
                bus.$emit('headerDisabledBus', true);
                this.$router.push('/dashboard/');
              }).catch((err) => {
                toast.error(err.data.user_msg);
                this.otp = '';
                this.userCheckNotValidLogin = true;
                this.userCheckValidLogin = false;
                this.submitted = false;
                clearTimeout(clearTimeoutState);
                this.countDown = 30;
              })
            return;
          }
          alert('Correct them errors!');
        });
      } else {
        this.$validator.validateAll().then((result) => {
          this.submitted = true;
          if (result) {
            this.loginBtnDisabled = true
            this.$store.dispatch('loginOtpVerifyAction', {
                email: this.verfiyIdentity,
                otp: this.otp
              })
              .then((res) => {
                document.getElementById('preloader').style.display = "flex";
                toast.success(res.data.msg);
                this.submitted = false;
                bus.$emit('headerDisabledBus', true);
                this.$router.push('/dashboard/');
              }).catch((err) => {
                console.log(err)
                toast.error(err.data.user_msg);
                this.otp = '';
                this.userCheckNotValidLogin = true;
                this.userCheckValidLogin = false;
                this.mblLogined = false;
                this.submitted = false;
                clearTimeout(clearTimeoutState);
                this.countDown = 30;
              })
            return;
          }
          alert('Correct them errors!');
        });
      }
    },
  },
}